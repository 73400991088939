import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUser = this.auth.authState;
  
  constructor(private auth: AngularFireAuth) { }

  signUp(email: string, password: string) {
    return new Promise((res, rej) => {
      this.auth.createUserWithEmailAndPassword(email, password)
      .then((user) => {
        res(user.user.uid);
      }).catch(err => {
        rej(err.message);
      })
    })
  }

  signIn(email: string, password: string){
    return new Promise((res, rej) => {
      this.auth.signInWithEmailAndPassword(email, password)
      .then((user) => {
        res(user);
      }).catch(err => {
        rej(err.message);
      })
    })
  }

  signOut() {
    return new Promise((res, rej) => {
      this.auth.signOut()
      .then(() => {
        res("logged out");
      }).catch(err => {
        rej(err.message);
      })
    })
  }

  passwordForgotten(email) {
    return new Promise((res, rej) => {
      this.auth.sendPasswordResetEmail(email)
        .then(() => {
          res("Successfully sent Passwort Reset Mail to " + email);
        }).catch((err) => {
          rej(err);
        })
    })
  }
}
