import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Booking } from '../_classes/booking';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private db: AngularFireDatabase) { }

  getAllBooking() {
    return this.db.list<Booking>("/bookings/").snapshotChanges().pipe(
      map(bookings => 
        bookings.map(booking => (
          {
            key: booking.key,
            ...booking.payload.val() 
          }
        ))
      )
    );
  }
  

  getAllBookingFromGroup(groupName) {
    return this.db.list<Booking>("/bookings/", ref => { return ref.orderByChild("groupName").equalTo(groupName)}).snapshotChanges().pipe(
      map(bookings => 
        bookings.map(booking => (
          {
            key: booking.key,
            ...booking.payload.val() 
          }
        ))
      )
    );
  }

  getAllBookingFromTable(tablekey) {
    return this.db.list<Booking>("/bookings/", ref => { return ref.orderByChild("tablekey").equalTo(tablekey)}).snapshotChanges().pipe(
      map(bookings => 
        bookings.map(booking => (
          {
            key: booking.key,
            ...booking.payload.val() 
          }
        ))
      )
    );
  }

  getAllBookingFromUserAndTable(uid, tablekey) {
    return this.db.list<Booking>("/bookings/", ref => { return ref.orderByChild("uid").equalTo(uid) && ref.orderByChild("tablekey").equalTo(tablekey)}).snapshotChanges().pipe(
      map(bookings => 
        bookings.map(booking => (
          {
            key: booking.key,
            ...booking.payload.val() 
          }
        ))
      )
    );
  }


  getAllBookingFromUser(uid) {
    return this.db.list<Booking>("/bookings/", ref => { return ref.orderByChild("uid").equalTo(uid)}).snapshotChanges().pipe(
      map(bookings => 
        bookings.map(booking => (
          {
            key: booking.key,
            ...booking.payload.val() 
          }
        ))
      )
    );
  }

  create(booking: Booking) {
    return new Promise((res, rej) => {
      this.db.list("/bookings/").push(booking).then(
        () => res("Successfully created Booking")
      ).catch((err) => {
        rej(err.message);
      })
    })
  }

  update(booking: Booking) {
    return new Promise((res, rej) => {
      this.db.list("/bookings/").set(booking.key, booking).then(
        () => res("Successfully updated Booking")
      ).catch((err) => {
        rej(err);
      })
    })
  }

  getBookingData(key) {
    return this.db.object<Booking>("/bookings/" + key).valueChanges();
  }
}
