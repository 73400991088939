import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { User } from '../_classes/user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private db: AngularFireDatabase) { }

  getAllUser() {
    return this.db.list<User>("/users/").snapshotChanges().pipe(
      map(users => 
        users.map(user => (
          {
            uid: user.payload.key,
            ...user.payload.val() 
          }
        ))
      )
    );
  }

  create(uid: string, user: User) {
    return new Promise((res, rej) => {
      this.db.list("/users/").set(uid, user).then(
        () => res(user.email + " successfully created")
      ).catch((err) => {
          //rej(err.message);
          rej(err);
        })
    })
  }

  update(user: User) {
    return new Promise((res, rej) => {
      this.db.list("/users/").set(user.uid, user).then(
        () => res(user.email + " successfully updated")
      ).catch((err) => {
        rej(err);
      })
    })
  }

  getUserData(uid) {
    return this.db.object<User>("/users/" + uid).valueChanges();
  }
}
